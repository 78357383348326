import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export type ToastStatus = 'success' | 'error' | 'loading';

export type ToastType = {
  id: string;
  status: ToastStatus;
  message?: string;
};

interface ToastContextType {
  toasts: ToastType[];
  createToast: (
    status: ToastStatus,
    message?: string,
    duration?: number,
  ) => string;
  updateToast: (id: string, status: ToastStatus, message?: string) => void;
  closeToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const createToast = useCallback(
    (status: ToastStatus, message?: string, duration = 3000) => {
      const id = uuidv4();
      setToasts((prevToasts) => [{ id, status, message }, ...prevToasts]);

      if (duration > 0) {
        setTimeout(() => {
          setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id),
          );
        }, duration);
      }

      return id;
    },
    [],
  );

  const updateToast = useCallback(
    (id: string, status: ToastStatus, message?: string) => {
      setToasts((prevToasts) =>
        prevToasts.map((toast) =>
          toast.id === id
            ? { ...toast, status, message: message ?? toast.message }
            : toast,
        ),
      );
    },
    [],
  );

  const closeToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider
      value={{ toasts, createToast, updateToast, closeToast }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToasts = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToasts must be used within a ToastProvider');
  }
  return context;
};
